@font-face {
  font-family: 'Gibson';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/Gibson-Regular.otf');
}

body {
  margin: 0;
  font-family: Gibson, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8FAFC;
}

label {
  font-family: Gibson
}

code {
  font-family: Gibson, serif;
}

.apexcharts-svg {
  border-radius: 18px;
}
